import React from 'react'
import './Loader.css'
const Loader = () => {
  return (
    <div className="loader">
        {/* <img src="https://media4.giphy.com/media/CPXvBvjTzHaAS9qrTJ/giphy.gif?cid=790b761186c18361014bef2987737ede406c683bac3bd403&rid=giphy.gif&ct=s" alt="" height={100}/> */}
      <h3>loading..............</h3>
    </div>
  )
}

export default Loader
