import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy, useEffect, useState } from "react";
import SEO from './components/SEO';
import Loader from './components/Loader/Loader'


import "./styles/bootstrap.min.css";
import "./styles/responsive.css";
import "./styles/reset.css";
import "./styles/css2.css";

// import Solution from './pages/solutionpage/Solution';
// import Technologies from './pages/technologiespage/Technologies';
// import ContactUs from './pages/contactUs/ContactUs';
// import About from './pages/AboutPage/About';

const LandingPage = lazy(() => import("./pages/landingPage/LandingPage"));
const ContactUs = lazy(() => import("./pages/contactUs/ContactUs"));
const About = lazy(() => import("./pages/AboutPage/About"));
const Solution = lazy(() => import("./pages/solutionpage/Solution"));
const Technologies = lazy(() => import("./pages/technologiespage/Technologies"));

 
 
function App() {
  return (
   <>
   <Router>
   <SEO dynamicTitle="companyName | Home" /> 
   <Suspense fallback={<Loader />}></Suspense>
   <Routes>
   <Route path="/" element={<LandingPage />} />
   <Route path="/about" element={<About/>} />
   <Route path="/contact" element={<ContactUs/>} />
   <Route path="/solutions" element={<Solution />} />
   <Route path="/technologies" element={<Technologies />} />
   </Routes>
   </Router>
   </>
  );
}

export default App;
